@tailwind base;

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border: 1px solid var(--primary);
}

@tailwind components;
@tailwind utilities;

:root {
  --primary: #D1D1D1;
  --secondary: #404040;
}

body {
  overscroll-behavior: none;
}

@layer base {
  body {
    @apply text-oshBase text-goj-charcoal;
  }

  h1 {
    @apply text-oshH1 font-bold text-goj-charcoal mb-12 leading-10;
  }

  h2 {
    @apply text-oshH2 font-bold text-goj-charcoal mb-5;
  }

  h3 {
    @apply text-xl font-semibold text-goj-charcoal mb-4;
  }

  h4 {
    @apply text-oshBase font-semibold text-goj-charcoal mb-4;
  }

  strong,
  b,
  th {
    @apply font-semibold;
  }

  a {
    @apply text-goj-secondary hover:underline hover:text-goj-charcoal;
  }

  a:active {
    @apply text-goj-primary;
  }

  a:visited {
    @apply text-goj-secondary;
  }

  footer a,
  footer a:active,
  footer a:visited,
  footer a:hover {
    @apply text-goj-white;
  }

  p {
    /* jj - remove line height  */
    @apply mb-4;
  }

  main ol {
    @apply list-decimal ml-5 mb-4;
  }

  main ul {
    @apply list-disc ml-5 mb-4;
  }

  table {
    @apply text-base;
  }

  main.pagecontent table {
    @apply w-full block overflow-x-auto border;
  }

  main.pagecontent table, tbody, th, td, tr {
    @apply text-left align-top p-1 border;
  }

  .hero h1 {
    @apply mb-4;
  }

  .hero h2 {
    @apply text-2xl;
  }

  .hero ul {
    @apply list-disc ml-6;
  }

  aside,
  nav,
  header,
  footer {
    @apply text-base;
  }

  .accordion button:not(.show) {
    @apply hidden lg:flex;
  }

  .content a {
    @apply border border-goj-secondary bg-goj-white text-goj-secondary rounded px-4 py-2 my-2 inline-block hover:text-goj-secondary-light hover:border-goj-secondary-light visited:text-goj-secondary;
  }

  strong.text-xl p {
    @apply mb-2;
  }
}